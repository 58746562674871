import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "512",
  height: "512",
  viewBox: "0 0 128 128"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#f0f0f0",
      d: "M12 27.751h104v72.497H12z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#0b55b1",
      d: "M116 54.667H57.333V27.751H38.667v26.916H11.751v18.666h26.916v26.916h18.666V73.333H116z"
    }, null, -1)
  ])))
}
export default { render: render }